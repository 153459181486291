@use '@angular/material' as mat;
@import "~@angular/material/theming";

@include mat-core();

@import "./theme.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #193b68;
}

p {
  color: #909daa;
}

.bad {
  color: #ac1500;
  font-size: 14px;
  line-height: 18px;
}

.good {
  color: #11ac00;
  font-size: 14px;
  line-height: 18px;
}

h1 {
  font-size: 46px;
  line-height: 50px;
  font-weight: bold;
  color: #757676;
}
