@use 'node_modules/@angular/material/index' as mat;
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
//
$dark-text: #172b4d;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);
body {
  --primary-color: #0064af;
  --primary-lighter-color: #b3d1e7;
  --primary-darker-color: #004896;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary:  (
  main: #0064af,
  lighter: #b3d1e7,
  darker: #004896,
  200: #0052cc, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #5f5f5f;
  --accent-lighter-color: #cfcfcf;
  --accent-darker-color: #434343;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #81d4fa,
  lighter: #d9f2fe,
  darker: #64c3f8,
  200: #5f5f5f, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
body {
  --warn-color: #ac1500;
  --warn-lighter-color: #e6b9b3;
  --warn-darker-color: #920c00;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);
//theme init
@include mat.all-component-themes($theme);
$font-family: "'Montserrat', Roboto, 'Helvetica Neue', sans-serif";
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, $font-family, -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, $font-family, -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, $font-family, 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, $font-family, 0.0074em),
  headline: mat.define-typography-level(26px, 32px, 400, $font-family, 0.0000em),
  title: mat.define-typography-level(18px, 32px, 600, $font-family, 0.0075em),
  subheading-2: mat.define-typography-level(20px, 24px, 600, $font-family, 0.0094em),//edited
  subheading-1: mat.define-typography-level(18px, 29px, 600, $font-family, 0.0094em),
  body-2: mat.define-typography-level(14px, 24px, 500, $font-family, 0.0179em),
  body-1: mat.define-typography-level(16px, 23px, 500, $font-family, 0.0179em),//edited
  button: mat.define-typography-level(16px, 18px, 500, $font-family, 0.0893em),//adjusted
  caption: mat.define-typography-level(12px, 20px, 500, $font-family, 0.0333em),//adjusted
  input: mat.define-typography-level(inherit, 1.125, 400, $font-family, 1.5px),
  field-name: mat.define-typography-level(14px, 1.33, 600, $font-family),
);

@include mat.core($fontConfig);
